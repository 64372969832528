import React, { useContext } from "react"
import { 
    Page, 
    Layout, 
    Card,
    Stack,
    TextStyle,
    Button
} from "@shopify/polaris"
import styled from 'styled-components'

const HelpdeskWrapper = styled.div`
    .learn-more {
        margin-top: 10px;
    }
    button {
        margin-top: 20px;
    }
`
function Helpdesk(props) {
    const { chartDateRange } = props;
        return (
            <Page>
                <Layout>
                    <Layout.Section>
                        <Card>
                            <Card.Section>
                                <Stack>
                                    <HelpdeskWrapper>
                                        <h3>
                                            <TextStyle variation="strong">Visit our helpdesk for 50+ resources and FAQ’s</TextStyle>
                                        </h3>
                                        <p className="learn-more">Learn more about:</p>
                                        <table>
                                            <tr>
                                                <td style={{width:'180px'}}>Discounts</td>
                                                <td>Checkout Extensions</td>
                                            </tr>
                                            <tr>
                                                <td>Setup</td>
                                                <td>Cart Drawer upsells</td>
                                            </tr>
                                            <tr>
                                                <td>Shopify Scripts</td>
                                                <td>AI upsells</td>
                                            </tr>
                                            <tr>
                                                <td>Post-purchase offers</td>
                                                <td>How to video’s</td>
                                            </tr>
                                            <tr>
                                                <td>Checkout.liquid</td>
                                                <td>and much more!</td>
                                            </tr>
                                        </table>
                                        <Button primary={true} onClick={
                                                () => {
                                                    window.open(`https://help.upsellplus.com/`, '_blank')
                                                }
                                            }>
                                            To the Helpdesk
                                        </Button>
                                    </HelpdeskWrapper>   
                                </Stack>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        )
}

export default Helpdesk
